import React, {ReactNode} from "react";
import {uuidv4} from "../../register/v2/Uuid";
import TranslationService from "../../../infra/TranslationService";
import {Action, Field} from "../../register/v2/Action";
import {GraphOptions} from "./GraphOptions";
import {
    ALL_REGISTRATIONS,
    FIRST_AMOUNT,
    FIRST_SELECTION,
    LAST_AMOUNT,
    LAST_SELECTION,
    PERIOD_FROM,
    PERIOD_SELECTION,
    PERIOD_TO
} from "../Constants";
import Event, {createEvent, setValue} from "../../register/v3/Event";
import getField from "../../register/v3/FieldFactory";

interface props {
    action: Action,
    graphOptions: GraphOptions,
    dateFormat: string,
    user: any,

    onPeriodChange: (from: string, to: string) => void,
    onFirstEventsChange: (firstEvents: number | undefined) => void,
    onLastEventsChange: (lastEvents: number | undefined) => void,
    onAllEventsChange: () => void,
}

interface state {
    currentSelection: string
}

class PeriodNumberOf extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.onPeriodChange = this.onPeriodChange.bind(this);
        this.onFirstSelectionChange = this.onFirstSelectionChange.bind(this);
        this.onLastSelectionChange = this.onLastSelectionChange.bind(this);

        this.state = {
            currentSelection: ALL_REGISTRATIONS,
        }
    }

    render(): ReactNode {
        const headline: string = TranslationService.translation("periodNumberOf");

        const periodSelection: React.ReactFragment = this.getRadioButton(PERIOD_SELECTION, true);
        const period: React.ReactFragment = this.getPeriodField(this.onPeriodChange);

        const firstSelection: React.ReactFragment = this.getRadioButton(FIRST_SELECTION, false);
        const firstAmount: React.ReactFragment = this.getNumberField(FIRST_AMOUNT, this.onFirstSelectionChange);
        const firstAmountLabel: React.ReactFragment = PeriodNumberOf.getLabel(FIRST_AMOUNT);

        const lastSelection: React.ReactFragment = this.getRadioButton(LAST_SELECTION, false, "testId-select-last");
        const lastAmount: React.ReactFragment = this.getNumberField(LAST_AMOUNT, this.onLastSelectionChange);
        const lastAmountLabel: React.ReactFragment = PeriodNumberOf.getLabel(LAST_AMOUNT);

        const allRegistrations: React.ReactFragment = this.getRadioButton(ALL_REGISTRATIONS, false);
        const allRegistrationsLabel: React.ReactFragment = PeriodNumberOf.getLabel(ALL_REGISTRATIONS);

        return <div>
            <h4>{headline}</h4>
            <div className={"row ml-0"}
                 onFocus={() => this.onCurrentSelectionChange(PERIOD_SELECTION)}
                 onClick={() => this.onCurrentSelectionChange(PERIOD_SELECTION)}>
                {periodSelection}
                {period}
            </div>

            <div className={"row ml-0"}
                 onFocus={() => this.onCurrentSelectionChange(LAST_SELECTION)}
                 onClick={() => this.onCurrentSelectionChange(LAST_SELECTION)}>
                {lastSelection}
                {lastAmount}
                {lastAmountLabel}
            </div>

            <div className={"row ml-0"}
                 onFocus={() => this.onCurrentSelectionChange(FIRST_SELECTION)}
                 onClick={() => this.onCurrentSelectionChange(FIRST_SELECTION)}>
                {firstSelection}
                {firstAmount}
                {firstAmountLabel}
            </div>

            <div className={"row ml-0"}
                 onClick={() => this.onCurrentSelectionChange(ALL_REGISTRATIONS)}>
                {allRegistrations}
                {allRegistrationsLabel}
            </div>
        </div>;
    }

    private getRadioButton(optionName: string, showLabel: boolean, testId?: string): React.ReactFragment {
        const name: string = "periodNumberOf";
        const id: string = uuidv4();
        const labelText: string = TranslationService.translation(optionName);
        const currentSelection: string = this.state.currentSelection;
        const checked: boolean = optionName === currentSelection;
        let label;
        if (showLabel) {
            label = <label htmlFor={id} className={"pr-1"}>{labelText}</label>
        } else {
            label = "";
        }
        return <div key={optionName}
                    onChange={() => this.onCurrentSelectionChange(optionName)}>
            <input type={"radio"}
                   name={name}
                   id={id}
                   readOnly={true}
                   data-testid={testId}
                   checked={checked}
                   aria-label={optionName}
            />
            {label}
        </div>;
    }

    private getPeriodField(onChange: (name: string, value: (string | string[]), duplicationIndex: string, valid: boolean) => void): React.ReactFragment {
        const field: Field = {
            name: "period",
            type: 'period',
            fieldLabel: true,
            showLabel: false,
            duplicationIndex: 0,
            showChildLabel: false,
            fieldSize: 13
        };

        let action = this.props.action;
        const duplicationIndex: string = '0';
        const user = this.props.user;

        let event: Event = createEvent();

        const graphOptions = this.props.graphOptions;
        if (graphOptions.periodSelection !== undefined) {
            const periodFrom: string = graphOptions.periodSelection.periodFrom;
            const name = field.name + '.from';
            event = setValue(name, periodFrom, duplicationIndex, true, field, event);
        }

        if (graphOptions.periodSelection !== undefined) {
            const periodTo: string = graphOptions.periodSelection.periodTo;
            const name = field.name + '.to';
            event = setValue(name, periodTo, duplicationIndex, true, field, event);
        }

        return getField(field, event, onChange, action, user);
    }

    private getNumberField(name: string, onChange: (name: string, value: (string | string[]), duplicationIndex: string, valid: boolean) => void): React.ReactFragment {
        const field: Field = {
            name: name,
            type: 'number',
            fieldLabel: false,
            showLabel: false,
            duplicationIndex: 0,
            fieldSize: 3,
            decimalPlaces: 0,
            min: 0
        };

        let action = this.props.action;
        const user = this.props.user;
        const duplicationIndex: string = '0';

        let event: Event = createEvent();
        let value: string = '';
        if (name === FIRST_AMOUNT) {
            if (this.props.graphOptions.firstSelection !== undefined) {
                const value: string = '' + this.props.graphOptions.firstSelection.amount;
                event = setValue(name, value, duplicationIndex, true, field, event);
            }
        }

        if (name === LAST_AMOUNT) {
            if (this.props.graphOptions.lastSelection !== undefined) {
                value = '' + this.props.graphOptions.lastSelection.amount;
                event = setValue(name, value, duplicationIndex, true, field, event);
            }
        }

        return getField(field, event, onChange, action, user);
    }

    private static getLabel(labelName: string): React.ReactFragment {
        const label = TranslationService.translation(labelName);

        const ariaLabel = labelName + "Label";
        return <div aria-label={ariaLabel}>
            {label}
        </div>
    }

    private onCurrentSelectionChange(optionName: string) {
        if (optionName === ALL_REGISTRATIONS) {
            this.props.onAllEventsChange();
        }
        this.setState({currentSelection: optionName})
    }

    private onPeriodChange(name: string, value: (string | string[]), duplicationIndex: string, valid: boolean) {
        if (duplicationIndex === '0' === valid) {
            // Trick Idea to think these arguments are used
        }

        let from: string = '';
        let to: string = '';

        if (name === PERIOD_FROM) {
            if (typeof value === 'string') {
                from = value;
            }
            if (Array.isArray(value)) {
                from = value[0];
            }
        } else {
            if (this.props.graphOptions.periodSelection !== undefined) {
                from = this.props.graphOptions.periodSelection.periodFrom;
            }
        }

        if (name === PERIOD_TO) {
            if (typeof value === 'string') {
                to = value;
            }
            if (Array.isArray(value)) {
                to = value[0];
            }
        } else {
            if (this.props.graphOptions.periodSelection !== undefined) {
                to = this.props.graphOptions.periodSelection.periodTo;
            }
        }

        const onPeriodChange = this.props.onPeriodChange;
        onPeriodChange(from, to);
    }

    private onFirstSelectionChange(name: string, value: (string | string[]), duplicationIndex: string, valid: boolean) {
        if (name === duplicationIndex === valid) {
            // Trick Idea to think these arguments are used
        }

        let firstEvents: number | undefined = 0;
        if (typeof value === 'string') {
            firstEvents = parseInt(value);
        }
        if (Array.isArray(value)) {
            firstEvents = parseInt(value[0]);
        }
        if (isNaN(firstEvents) || firstEvents === 0) {
            firstEvents = undefined;
        }

        const onFirstEventsChange = this.props.onFirstEventsChange;
        onFirstEventsChange(firstEvents);
    }

    private onLastSelectionChange(name: string, value: (string | string[]), duplicationIndex: string, valid: boolean) {
        if (name === duplicationIndex === valid) {
            // Trick Idea to think these arguments are used
        }

        let lastEvents: number | undefined = 0;
        if (typeof value === 'string') {
            lastEvents = parseInt(value);
        }
        if (Array.isArray(value)) {
            lastEvents = parseInt(value[0]);
        }
        if (isNaN(lastEvents) || lastEvents === 0) {
            lastEvents = undefined;
        }

        const onLastEventsChange = this.props.onLastEventsChange;
        onLastEventsChange(lastEvents);
    }

}

export default PeriodNumberOf;
