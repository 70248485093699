export interface FilterValue {
    fieldName: string;
    type: FilterValueType;
    erase?: () => void;
    value?: string[];
}

export interface FilterValueDate extends FilterValue {
    from: string,
    to: string
}

export interface FilterValueNumber extends FilterValue {
    min?: number;
    max?: number;
}

export enum FilterValueType {
    Date = "date",
    Number = "number"
}