import React from 'react';
import TranslationService from "../../../infra/TranslationService";
import {fetchArrayOf} from "../../../infra/BackendService";

export interface GroupedRequests {
    actionName: string,
    requests: Requests[]
}

interface Requests {
    countersignId: string,
    requestDate: string,
    requesterName: string
}

interface props {
    refreshExpiredToken: () => void
    securityToken: string
}

interface state {
    groupedRequests: GroupedRequests[] | undefined
}


class CountersignRequests extends React.Component<props, state> {

    constructor(props: props) {
        super(props);

        this.state = {
            groupedRequests: undefined
        }
    }


    render() {
        let formHeaderTranslated = TranslationService.translation("countersign-requests.form.header");
        const formHeader = <div className={"row justify-content-center actionHeader pt-1"}>
            <h4>{formHeaderTranslated}</h4>
        </div>;
        let groupedRequests = this.state.groupedRequests;
        if (groupedRequests !== undefined && groupedRequests?.length === 0) {
            return <div>{TranslationService.translation("countersign-requests.no.requests")}</div>;
        }

        if (groupedRequests === undefined) {
            return <div
                className={"spinner"}
                aria-label={"waiting for requests"}
            />
        }
        const requesterNameHeader = TranslationService.translation("countersign-requests.requester.name");
        const requestedDateHeader = TranslationService.translation("countersign-requests.date");
        const countersignLinkText = TranslationService.translation("countersign-requests.countersign.link")
        console.log(groupedRequests);
        let allGroupReq = groupedRequests.map((groupedRequest: GroupedRequests) => {
            let pendingRequests = TranslationService.translation("countersign-requests.pending.for");
            const actionNameHeader = TranslationService.translation(groupedRequest.actionName);
            const groupedRequestHeader = <h4 className={"mt-4 mb-2"}
                                             aria-label={"pending.requests"}>{pendingRequests + " " + actionNameHeader + ":"}</h4>;
            const requestHeader = <div className={"row strong"}
                                       aria-label={groupedRequest.actionName + ".request.header"}>
                <div className={"col"}>{requestedDateHeader}</div>
                <div className={"col"}>{requesterNameHeader}</div>
                <div className={"col"}></div>
            </div>;
            const requests: Requests[] = groupedRequest.requests;
            const requestDetails = requests.map((request: Requests) => {
                const date = request.requestDate;
                const requesterName = request.requesterName;

                return <div className={"row mb-1"} aria-label={date + requesterName} key={date + requesterName}>
                    <div className={"col"}>{date}</div>
                    <div className={"col"}>{requesterName}</div>
                    <div className={"col"}><a href={"#/countersign/" + request.countersignId}>{countersignLinkText}</a>
                    </div>
                </div>;
            });
            return <div key={groupedRequest.actionName}>
                {groupedRequestHeader}
                {requestHeader}
                {requestDetails}
                <hr/>
            </div>

        });

        return <div>
            {formHeader}
            <div className={"container"}>
                {allGroupReq}
            </div>
        </div>
    }

    componentDidMount() {
        const url = "/api/v2/countersignrequests";
        fetchArrayOf<GroupedRequests>(url).then(requests => {
                this.setState({groupedRequests: requests})
            }
        )
    }
}

export default CountersignRequests;