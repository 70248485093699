import React from "react";
import {Link, NavLink, withRouter} from 'react-router-dom';
import "./FullNavBar.css";
import "./FullNavBarMobile.css";
import {BackendContext, BackendInterface} from "../../infra/BackendContext";
import logo from "../../images/logo-inverted.png";
import {NavBar, NavBarOrganisation} from "../model/NavBar";
import {User} from "../model/User";
import {Organisation} from "../model/Organisation";
import {UserData} from "../model/UserData";
import {selectOrganisation} from "../organisation/SelectOrganisation";
import {logoutWithBackup} from "../../infra/Logout";

//TODO samma som under
export interface NavBarItem {
    route: string,
    translatedName: string
    subList: {
        name: string,
        version: string
        nameTranslated: string,
        modelVersion: string
    } []
}

//TODO flytta den här till en överklass, antagligen till hubben, eller en modellsak
export interface NavbarData {
    items: NavBarItem[]
    brand: any
    organisations: any[]
}

interface props {
    user: User
    updateNavBar: (navBar: NavBar) => void
    setCurrentOrganisation: (organisation: Organisation | undefined) => void
    userData: UserData,
    refreshExpiredToken: () => void,
    securityToken: string,
    setAction: (action: string) => void,
    setStatsAction: (statsAction: string) => void,
    history: string[],
    setPath: (path: string) => void,
    nav: NavbarData;
}

interface state {
    rootLinksInvisible: boolean
    ypos: number,
    hasScrolled: boolean,
    sublistYpos: Position[]
}

function nop() {
}

interface Position {
    id: string,
    yPos: number
}

const headerId = "navbarHeaderContainer";
const navPadding = "nav-up";
const NAVBAR_HEIGHT = 66;

/*
                                                // the row below has a magic number 60 to take into account odd behaviour on iphones (bouncing on bottom, reintroduces navbar)
                                                (this is a comment from the old navbar. the important part is the fact that the iphone bounce draws down the menu again
                                                (dont delete this)
                                                (this time it is fixed by having padding in bottom. another fix could be the look at the navbar dropdown logic
 */

class FullNavBarMobile extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: props) {
        super(props);

        this.state = {
            ypos: 0,
            hasScrolled: false,
            rootLinksInvisible: false,
            sublistYpos: []
        };
    }

    componentDidMount() {
        document.addEventListener("scroll", this.handleScrolling);

        /*
        let elements: HTMLCollectionOf<Element> = document.getElementsByClassName("sublistScrollable");
        if (elements !== undefined) {
            for (let i = 0; i < elements.length; i++) {
                const sublist: Element = elements[i];
                sublist.addEventListener("scroll", () => this.handleScrolling());
            }
        }

         */


        setInterval(() => {
            if (this.state.hasScrolled) {
                this.hasScrolled();
                this.hasScrolledMenu();
                this.setState({hasScrolled: false});
            }
        }, 250);
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.handleScrolling);

        /*
        let element: HTMLCollectionOf<Element> = document.getElementsByClassName("sublistScrollable");
        if (element !== undefined) {
            for (let i = 0; i < element.length; i++) {
                const sublist: Element = element[i];
                sublist.removeEventListener("scroll", () => this.handleScrolling());
            }
        }

         */
    }

    render(): React.JSX.Element {
        if (this.props.userData.navBar !== undefined) {
            const organisations: NavBarOrganisation[] | undefined = this.props.userData.navBar.organisations;
            const organisationSelector: React.JSX.Element = this.getOrganisationSelector(organisations);

            const {userData} = this.props;
            const nav = userData.navBar;
            //TODO rever<At

            //navbarHeaderContainer
            return <div id={headerId} className={"navbarHeaderContainer"}>
                <nav className={"nav navbar navBarStyle"}>
                    <Link
                        className={"btn-custom-tage-primary navbar-brand"}
                        to={userData === undefined ? "/" : userData.navBar.brand.route}>
                        <img src={logo} alt={"logo"} height={42}/>
                    </Link>

                    {organisationSelector}

                    <button id={"buttonID3"} className="navbar-toggler pt-3 pb-3" type="button"
                            data-toggle="collapse" data-target="#menu23Mobile">☰
                    </button>

                    <div className="collapse navbar-collapse" id="menu23Mobile">
                        <ul className={"navbar-nav ml-auto mobileScroll"}>
                            {
                                nav.items.map((buttonData: NavBarItem) => this.createNavbarButton(buttonData))
                            }
                            <li className={"iphone-pad"}/>
                        </ul>
                    </div>
                </nav>
            </div>
        }
        return <div/>;

    }


    private getOrganisationSelector(organisations: NavBarOrganisation[] | undefined): React.JSX.Element {
        if (organisations && organisations.length > 1) {
            const options: React.JSX.Element[] = organisations.map((value: NavBarOrganisation) => {
                return <option key={value.id}
                               value={value.id}
                               className={"navbar-org-select-option"}>
                    {value.name}
                </option>
            });

            let defaultValue: string = '';
            for (const item of organisations) {
                if (item.selected) {
                    defaultValue = item.id;
                    break;
                }
            }

            return <select className={"navbar-org-select"}
                           aria-label={"Organisation selector"}
                           defaultValue={defaultValue}
                           onChange={(event: React.SyntheticEvent<HTMLSelectElement>) => this.changeOrganisation(event)}>
                {options}
            </select>;
        }

        return <div/>;
    }

    private fetchingOrganisation(fetchingOrganisation: boolean): void {
        console.log('Maybe display the dancing balls while changing the organisation? It was just set to ' + fetchingOrganisation);
    }

    private changeOrganisation(event: React.SyntheticEvent<HTMLSelectElement>) {
        const organisationId: string = event.currentTarget.value;
        const user: User = this.props.user;
        const updateNavBar = this.props.updateNavBar;
        const setCurrentOrganisation = this.props.setCurrentOrganisation;
        const post = this.context.post;
        const fetchingOrganisation = this.fetchingOrganisation;

        selectOrganisation(organisationId,
            user,
            updateNavBar,
            setCurrentOrganisation,
            post,
            fetchingOrganisation
        );
    }


    private createNavbarButton(buttonData: NavBarItem): any {

        if (buttonData.subList && buttonData.subList.length > 0) {
            //make a button that un-collapses the sublist under it

            const sublistTarget = buttonData.route + "_scrollableSublist";
            return <React.Fragment key={buttonData.route}>
                <div id={buttonData.route + "_navigationId"}
                     className="btn btn-custom-tage-primary nav-link m-1 rootNavLink"
                     data-toggle="collapse"
                     data-target={"#" + sublistTarget}>
                    {buttonData.translatedName}
                </div>

                <div className="collapse" id={sublistTarget}>
                    <div className={"navbar-nav ml-auto"}>
                        {
                            buttonData.subList.map(action => {
                                return this.getSublistButton(action, buttonData);
                            })
                        }
                    </div>
                </div>
            </React.Fragment>

        } else {
            //make a regular menu button with no sublist
            const isLogoutButton = buttonData.route === "/logout"

            return <li className={"nav-item"} key={buttonData.route}
                       data-toggle="collapse"
                       data-target="#menu23Mobile">
                <NavLink
                    className={"btn btn-custom-tage-primary nav-link m-1 rootNavLink"}
                    to={buttonData.route}
                    onClick={isLogoutButton ? () => this.logout() : () => nop()}
                    activeClassName={"active"}>{buttonData.translatedName}
                </NavLink>
            </li>
        }

    }

    private getSublistButton(action: { name: string; version: string; nameTranslated: string; modelVersion: string }, buttonData: NavBarItem) {
        return (
            <div id={action.name}
                 key={buttonData.route + "_" + action.name}
                 className={buttonData.route + "_sublist sublist m-1"}
                 style={{background: "#323232"}}>
                <div className={"btn btn-custom-tage-primary nav-link"}
                     data-toggle="collapse"
                     data-target="#menu23Mobile"
                     onClick={() => this.clickOnSubmenu(action.name, buttonData.route, buttonData.route + "_sublist")}>
                    {action.nameTranslated}
                </div>
            </div>
        );
    }

    private logout() {
        logoutWithBackup();
    }

    hasScrolled() {
        const currentYpos = window.scrollY;
        const oldPos = this.state.ypos;
        let delta = 5;
        if (Math.abs(oldPos - currentYpos) <= delta) { //minimal change, do nothing
            return;
        }

        let header = document.getElementById(headerId);
        if (oldPos >= currentYpos) {
            if (header !== null) {
                header.classList.remove(navPadding)
            }
        } else if (currentYpos >= NAVBAR_HEIGHT) {
            if (header !== null) {
                header.classList.add(navPadding)
            }
        }

        this.setState({ypos: currentYpos});
    }

    hasScrolledMenu() {
        let element: HTMLCollectionOf<Element> = document.getElementsByClassName("sublistScrollable");
        let lastYPositions: Position[] = this.state.sublistYpos;
        let header: HTMLElement | null = document.getElementById(headerId);

        if (element !== undefined) {
            for (let i = 0; i < element.length; i++) {
                const sublist: Element = element[i];
                const sublistId: string = sublist.id;
                const currentYpos: number = sublist.scrollTop;
                let wantedSublistPos: Position | undefined = lastYPositions.find(e => e.id === sublistId);

                if (wantedSublistPos === undefined) {
                    let newSublistPos = {
                        "id": sublistId,
                        "yPos": 0
                    }
                    lastYPositions.push(newSublistPos);
                    break;
                }

                if (wantedSublistPos.yPos > currentYpos) {
                    if (header !== null) {
                        header.classList.remove(navPadding)
                    }
                } else if (wantedSublistPos.yPos < currentYpos) {
                    if (header !== null) {
                        header.classList.add(navPadding)
                    }
                }

                wantedSublistPos.yPos = currentYpos;
            }
        }
    }

    handleScrolling = () => {
        this.setState({hasScrolled: true});
    }

    private clickOnSubmenu(actionName: string, route: string, submenuId: string) {
        //let path = route + "/" + actionName + "/" + submenuId;
        //this.setPath(path); //TOOD errorhandling
        console.log(submenuId)
        if (route === "/statistics") {
            this.props.setStatsAction(actionName);
        } else {
            this.props.setAction(actionName);
        }

        this.props.history.push(route);
        this.closeMenu();
    }

    closeMenu(): void {
    }
}


export default withRouter(FullNavBarMobile);
