import {ActionService} from "../../register/v2/ActionService";
import {StatisticsService} from "../StatisticsService";
import React from "react";
import {Action, Error} from "../../register/v2/Action";
import Graphs from "../../graph/Graphs";
import Selection from "./Selection";
import FilterV2 from "./Filter";
import {addValueToGraphOptions, AgeFilterSelection, GraphOptions} from "./GraphOptions";
import {getEvent} from "../../register/v2/Util";
import Event, {instanceOfEventOrGoal} from "../../register/v2/Event";
import {FieldError} from "../../fields/v2/FieldComponent";
import {DESKTOP, STATISTICS, V3} from "../../../infra/Constants";
import FormHeader from "../../register/v2/FormHeader";
import ExperienceDetailsPage from "../v1/ExperienceDetailsPage";
import GoalPage from "../v1/GoalPage";
import {getAgeSelections} from "./AgeFilter";
import TranslationService from "../../../infra/TranslationService";
import {User} from "../../model/User";
import {Organisation} from "../../model/Organisation";
import ExportEventsButton from "../../splashpage/ExportEventsButton";
import {hasRight} from "../../../utils/HasRight";
import RegisterResultFailureMessage from "../../register/v2/RegisterResult";

interface props {
    actionName: string,
    actionVersion: number,
    dateFormat: string,
    actionService: ActionService,
    statisticsService: StatisticsService,
    user: User,
    currentOrganisation?: Organisation
}

interface state {
    action: Action | undefined,
    actionError: Error | undefined,
    graphOptions: GraphOptions,
    showGoals: boolean,
    updateGraphs: boolean,
    experiences: Set<string>,
    showFailureMessage: boolean
}

class Statistics extends React.Component<props, state> {
    amIMounted: boolean = false;
    graphRef: any;
    selectionRef: any;
    fieldReferences: Map<string, any> = new Map<string, any>();

    constructor(props: props) {
        super(props);

        this.onGroupByChange = this.onGroupByChange.bind(this);
        this.onGraphTypeChange = this.onGraphTypeChange.bind(this);
        this.onMovingAverageChange = this.onMovingAverageChange.bind(this);
        this.onAgeChange = this.onAgeChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onAgeSelectionChange = this.onAgeSelectionChange.bind(this);
        this.onPeriodChange = this.onPeriodChange.bind(this);
        this.onFirstEventsChange = this.onFirstEventsChange.bind(this);
        this.onLastEventsChange = this.onLastEventsChange.bind(this);
        this.onAllEventsChange = this.onAllEventsChange.bind(this);

        this.showDetails = this.showDetails.bind(this);
        this.showSummary = this.showSummary.bind(this);
        this.updateGraphs = this.updateGraphs.bind(this);

        const graphOptions: GraphOptions = {
            groupBy: ""
        };
        graphOptions.currentOrganisation = this.props.currentOrganisation;

        const experiences = new Set([
            "anaesthesia-experience",
            "surgery-experience",
            "internal-medicine-experience",
            "intern-se-experience"
        ]);

        this.state = {
            action: undefined,
            actionError: undefined,
            graphOptions: graphOptions,
            showGoals: false,
            updateGraphs: true,
            experiences: experiences,
            showFailureMessage: false
        }
    }

    render(): React.ReactNode {
        if (this.state.action === undefined) {
            return <div data-testid={"no-action-spinner"} className={"spinner"}/>
        }

        let failureModal: React.JSX.Element = <div/>;
        const failureMessage = ["Något gick tokigt! Vi jobbar på att lösa det."];

        if (this.state.showFailureMessage) {
            failureModal =
                <RegisterResultFailureMessage message={failureMessage}
                                              hideFailureMessage={this.hideFailureMessage}/>;
        }

        this.graphRef = React.createRef();
        this.selectionRef = React.createRef();

        const actionName: string = this.props.actionName;
        const actionVersion: number = this.props.actionVersion;
        const graphOptions: GraphOptions = this.state.graphOptions;
        const dateFormat: string = this.props.dateFormat;
        const statisticsService = this.props.statisticsService;
        const action: Action = this.state.action;

        const formHeader = this.getFormHeader(actionName);
        const graphs = this.getGraphs(graphOptions, actionName, actionVersion, statisticsService);
        const searchButton = this.getSearchButton();
        const selection = this.getSelection(action, dateFormat);
        const filter = this.getFilter(action, dateFormat);

        const experienceStuff = this.renderExperience(actionName, actionVersion, formHeader, this.props.user);
        if (experienceStuff !== undefined) {
            return experienceStuff;
        }
        return <div>
            {formHeader}
            <div className={"container"}>
                {graphs}
                {searchButton}
                {failureModal}
                {selection}
                {filter}
            </div>
        </div>;
    }

    hideFailureMessage = () => {
        this.setState({showFailureMessage: false})
    }

    showFailureMessage = (showFailureMessage: boolean) => {
        this.setState({showFailureMessage: showFailureMessage})
    }

    private getFormHeader(actionName: string) {
        return <FormHeader actionName={actionName} actionType={STATISTICS}/>;
    }

    private getGraphs(graphOptions: GraphOptions, actionName: string, actionVersion: number, statisticsService: StatisticsService): React.ReactFragment {
        const frontendVersion: string = this.getFrontendVersion();
        const graphPlaceholder: any = {
            "data": {
                "columns": [],
                "type": "pie"
            }
        };

        if (frontendVersion === V3) {
            if (graphOptions.groupBy === '') {
                return <div/>;
            }

            if (this.state.updateGraphs) {
                return <Graphs key={'1'}
                               actionName={actionName}
                               actionVersion={actionVersion}
                               showDescription={true}
                               graphOptions={graphOptions}
                               statisticsService={statisticsService}
                               graphPlaceholder={undefined}
                />;
            } else {
                return <Graphs key={'2'}
                               actionName={actionName}
                               actionVersion={actionVersion}
                               showDescription={true}
                               graphOptions={graphOptions}
                               statisticsService={statisticsService}
                               graphPlaceholder={graphPlaceholder}
                />;
            }
        } else {
            if (graphOptions.groupBy === '') {
                return <div/>;
            }

            if (this.state.updateGraphs) {
                return <Graphs key={'3'}
                               ref={this.graphRef}
                               actionName={actionName}
                               actionVersion={actionVersion}
                               showDescription={true}
                               graphOptions={graphOptions}
                               statisticsService={statisticsService}
                               graphPlaceholder={undefined}
                />;
            } else {
                return <Graphs key={'4'}
                               actionName={actionName}
                               actionVersion={actionVersion}
                               showDescription={true}
                               graphOptions={graphOptions}
                               statisticsService={statisticsService}
                               graphPlaceholder={graphPlaceholder}
                />;
            }
        }
    }

    private getSearchButton(): React.ReactFragment {
        const searchTranslation: string = TranslationService.translation("show graphs");

        const searchButton = <div className={"d-flex justify-content-end pt-3 m-0"}>
            <button aria-label={"search button"}
                    name={'search'}
                    className={"btn btn-save btn-submitNewReg"}
                    onClick={() => this.search()}>
                {searchTranslation}
            </button>
        </div>;

        const actionName: string = this.props.actionName;
        const version: number = this.props.actionVersion;
        const user: User = this.props.user;
        const hasExportRight = hasRight(user, actionName, "export-events");

        const exportButton: React.JSX.Element = <div className={"btn-save d-flex justify-content-end pt-3 m-0"}>
            <ExportEventsButton hasExportRight={hasExportRight}
                                buttonText={"Ladda ner din data"}
                                showFailureMessage={this.showFailureMessage}
                                actionName={actionName}
                                version={version}
                                scope={'user'}

            />
        </div>
        let organisation = this.props.currentOrganisation;
        let exportOrganisationButton: React.JSX.Element = <div
            className={"btn-save d-flex justify-content-end pt-3 m-0"}/>;
        let exportParentOrganisationButton: React.JSX.Element = <div
            className={"btn-save d-flex justify-content-end pt-3 m-0"}/>;
        if (organisation) {
            let name = organisation.organisationName;
            let rightName = actionName + "-" + name;
            let rightType = "export-organisation-events";
            let hasOrganisationExportRight = hasRight(user, rightName, rightType);
            let alias = this.getOrganisationAlias(name);
            let buttonText = "Exportera " + alias + "s registreringar";
            let organisationId = organisation.organisationId;

            exportOrganisationButton = <div className={"btn-save d-flex justify-content-end pt-3 m-0"}>
                <ExportEventsButton hasExportRight={hasOrganisationExportRight}
                                    buttonText={buttonText}
                                    showFailureMessage={this.showFailureMessage}
                                    actionName={actionName}
                                    version={version}
                                    organisationId={organisationId}
                                    scope={'organisation'}
                />
            </div>
            let hasParentOrganisationExportRight = hasRight(user, rightName, "export-parent-organisation-events");

            exportParentOrganisationButton = <div className={"btn-save d-flex justify-content-end pt-3 m-0"}>
                <ExportEventsButton hasExportRight={hasParentOrganisationExportRight}
                                    buttonText={buttonText}
                                    showFailureMessage={this.showFailureMessage}
                                    actionName={actionName}
                                    version={version}
                                    organisationId={organisationId}
                                    scope={'parent_organisation'}
                />
            </div>
        }

        let hasWorldExportRight = hasRight(user, actionName, "export-world-events");

        let worldButtonText = "Ladda ner all data";
        const exportWorldButton: React.JSX.Element = <div className={"btn-save d-flex justify-content-end pt-3 m-0"}>
            <ExportEventsButton hasExportRight={hasWorldExportRight}
                                buttonText={worldButtonText}
                                showFailureMessage={this.showFailureMessage}
                                actionName={actionName}
                                version={version}
                                scope={'world'}
            />
        </div>

        return <div>
            <hr/>
            <div className={"row"}>
                <div className={"col"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            {searchButton}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            {exportButton}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            {exportOrganisationButton}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            {exportParentOrganisationButton}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            {exportWorldButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    private getSelection(action: Action, dateFormat: string) {
        const graphOptions: GraphOptions = this.state.graphOptions;
        const user = this.props.user;
        const onGroupByChange = this.onGroupByChange;
        const onGraphTypeChange = this.onGraphTypeChange;
        const onMovingAverageChange = this.onMovingAverageChange;
        const onPeriodChange = this.onPeriodChange;
        const onFirstEventsChange = this.onFirstEventsChange;
        const onLastEventsChange = this.onLastEventsChange;
        const onAllEventsChange = this.onAllEventsChange;

        return <Selection ref={this.selectionRef}
                          action={action}
                          graphOptions={graphOptions}
                          dateFormat={dateFormat}
                          device={DESKTOP}
                          onGroupByChange={onGroupByChange}
                          onGraphTypeChange={onGraphTypeChange}
                          onMovingAverageChange={onMovingAverageChange}
                          onPeriodChange={onPeriodChange}
                          onFirstEventsChange={onFirstEventsChange}
                          onLastEventsChange={onLastEventsChange}
                          onAllEventsChange={onAllEventsChange}
                          user={user}
        />;
    }

    private getOrganisationAlias(organisation: string): string {
        let alias = organisation.replace(" - Ambulanshelikopter", "");
        alias = alias.replace("sregionen", "");
        return alias.replace("Region ", "");
    }

    private getFilter(action: Action, dateFormat: string): React.ReactFragment {
        return <FilterV2 action={action}
                         fieldReferences={this.fieldReferences}
                         dateFormat={dateFormat}
        />;
    }

    private renderExperience(actionName: string, actionVersion: number, formHeader: React.JSX.Element, user: User) {
        const experiences = this.state.experiences;

        if (experiences.has(actionName)) {
            const showGoals: boolean = this.state.showGoals;
            if (showGoals) {
                const goalPage = this.getGoalPage(actionName, actionVersion);
                return <div>
                    {formHeader}
                    <div className={"container"}>
                        {goalPage}
                    </div>
                </div>
            }

            const experienceDetailsPage = this.getExperienceDetailsPage(actionName, actionVersion, user);
            return <div>
                {formHeader}
                <div className={"container"}>
                    {experienceDetailsPage}
                </div>
            </div>
        }

        return undefined;
    }

    private getGoalPage(actionName: string, actionVersion: number) {
        return <GoalPage actionName={actionName}
                         actionVersion={actionVersion}
                         showDetails={this.showDetails}
        />;
    }

    private getExperienceDetailsPage(actionName: string, actionVersion: number, user: User) {
        return <ExperienceDetailsPage currentContent={actionName}
                                      actionName={actionName}
                                      actionVersion={actionVersion}
                                      showSummary={this.showSummary}
                                      user={user}
        />;
    }

    private search() {
        this.setState({
            updateGraphs: true
        });

        const frontendVersion: string = this.getFrontendVersion();
        if (frontendVersion !== V3) {
            const graphOptions: GraphOptions = this.harvestOptions()
            this.setState({
                graphOptions: graphOptions,
                updateGraphs: true
            });
        }
    }

    private onGroupByChange(groupBy: string): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.groupBy = groupBy;

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    };

    private onGraphTypeChange(graphType: string): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.graphType = graphType;

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    };

    private onMovingAverageChange(movingAverage: number | undefined): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.movingAverage = movingAverage;

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    };

    onPeriodChange(from: string, to: string): void {
        const graphOptions: GraphOptions = this.state.graphOptions;

        graphOptions.periodSelection = {
            periodFrom: from,
            periodTo: to
        };

        graphOptions.lastSelection = undefined;
        graphOptions.firstSelection = undefined;

        this.setState({graphOptions: graphOptions});
    }

    onFirstEventsChange(firstEvents: number | undefined): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.periodSelection = undefined;
        graphOptions.lastSelection = undefined;

        if (firstEvents !== undefined) {
            graphOptions.firstSelection = {amount: firstEvents};
        } else {
            graphOptions.firstSelection = undefined;
        }
        this.setState({graphOptions: graphOptions});
    }

    onLastEventsChange(lastEvents: number | undefined): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.periodSelection = undefined;
        graphOptions.firstSelection = undefined;

        if (lastEvents !== undefined) {
            graphOptions.lastSelection = {amount: lastEvents};
        } else {
            graphOptions.lastSelection = undefined;
        }

        this.setState({graphOptions: graphOptions});
    }

    private onAllEventsChange(): void {
        const graphOptions: GraphOptions = this.state.graphOptions;
        graphOptions.periodSelection = undefined;
        graphOptions.firstSelection = undefined;
        graphOptions.lastSelection = undefined;

        this.setState({graphOptions: graphOptions});
    };

    private onAgeChange(): void {
        // eslint-disable-next-line no-throw-literal
        throw 'Not yet implemented';
    };

    private onValueChange(name: string, value: (string | string[]), duplicationIndex: string, valid: boolean): void {
        if (name === duplicationIndex === valid) {
            // Trick Idea to think these arguments are used
        }

        let currentGraphOptions = this.state.graphOptions;
        let graphOptions = addValueToGraphOptions(value, name, currentGraphOptions);

        this.setState({
            graphOptions: graphOptions,
            updateGraphs: false
        });
    };

    private onAgeSelectionChange(ageSelection: AgeFilterSelection): void {
        const graphOptions: GraphOptions = this.state.graphOptions;

        if (graphOptions.ageSelection === undefined) {
            graphOptions.ageSelection = [];
            graphOptions.ageSelection.push(ageSelection);
        } else {
            let ageFilterSelections: AgeFilterSelection [] = graphOptions.ageSelection.filter((ags: AgeFilterSelection) => ags.name !== ageSelection.name);
            ageFilterSelections.push(ageSelection);
            graphOptions.ageSelection = ageFilterSelections;
        }

        this.setState({graphOptions: graphOptions});
    }

    showDetails() {
        this.setState({showGoals: false})
    }

    showSummary() {
        this.setState({showGoals: true})
    }

    componentDidMount() {
        this.amIMounted = true;
        this.setAction();
        window.addEventListener("input", this.updateGraphs);
    }

    componentWillUnmount() {
        this.amIMounted = false;
        window.removeEventListener("input", this.updateGraphs);
    }

    private setAction() {
        const name: string = this.props.actionName;
        const version: number = this.props.actionVersion;
        const type: string = STATISTICS;
        const actionService: ActionService = this.props.actionService;

        const actionPromise: Promise<Action | Error> = actionService.getAction(name, version, type);
        actionPromise.then((action: Action | Error) => {
            if (this.amIMounted) {
                if (!('reason' in action)) {
                    if (action.groupBy !== undefined) {
                        const groupBy: string = action.groupBy;
                        this.setState({
                            action: action,
                            graphOptions: {
                                groupBy: groupBy
                            }
                        });
                    } else {
                        this.setState({
                            action: action
                        });
                    }
                } else {
                    this.setState({actionError: action});
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    private updateGraphs() {
        this.setState({updateGraphs: false});
    }

    harvestOptions(): GraphOptions {
        const actionName = this.props.actionName;
        const actionVersion = this.props.actionVersion;
        const references = this.fieldReferences;
        const selection: Selection = this.selectionRef.current;

        const groupBy: string = selection.groupBy();
        const currentOrganisation: Organisation | undefined = this.props.currentOrganisation;
        const graphType: string = selection.graphType();
        const movingAverage: number = selection.movingAveragePeriod();
        const periodNumberOf: any = selection.periodNumberOf();
        const graphOptions: GraphOptions = {
            groupBy: groupBy,
            currentOrganisation: currentOrganisation,
            graphType: graphType,
            movingAverage: movingAverage
        }

        if (periodNumberOf !== {}) {
            if (periodNumberOf.periodSelection !== undefined) {
                graphOptions.periodSelection = {
                    periodFrom: periodNumberOf.periodSelection.periodFrom,
                    periodTo: periodNumberOf.periodSelection.periodTo
                }
            }
            if (periodNumberOf.lastSelection !== undefined) {
                graphOptions.lastSelection = {
                    amount: periodNumberOf.lastSelection.amount
                }
            }
            if (periodNumberOf.firstSelection !== undefined) {
                graphOptions.firstSelection = {
                    amount: periodNumberOf.firstSelection.amount
                }
            }
        }

        const filter: Event | FieldError[] = getEvent(actionName, actionVersion, references);
        if (instanceOfEventOrGoal(filter)) {
            graphOptions.values = filter.values;
            graphOptions.ageSelection = getAgeSelections(references);
        }

        return graphOptions;
    }

    private getFrontendVersion(): string {
        const action: Action | undefined = this.state.action;
        if (action !== undefined) {
            return action.frontendVersion;
        }

        return '';
    }
}

export default Statistics;
