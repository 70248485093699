import React, {createRef, ReactElement} from 'react';
import "./Register.css"
import "../../../infra/Util.css"
import Graphs from "../../graph/Graphs";
import Form from "./Form";
import FormNG from "../v3/Form";
import History from "./History";
import {ActionService} from "./ActionService";
import {Action, Error} from "./Action";
import TranslationService from "../../../infra/TranslationService";
import {addEventValuesToForm, addGoalValuesToForm, clearForm, getEvent, getGoal} from "./Util";
import Event, {instanceOfEventOrGoal} from "./Event";
import EventNG, {createEvent, getEventForBackend, getEventForFrontend} from "../v3/Event";
import {FieldError} from "../../fields/v2/FieldComponent";
import {uuidv4} from "./Uuid";
import {DESKTOP, GOAL, MOBILE, V3} from "../../../infra/Constants";
import {EventBackend} from "./EventBackend";
import {StatisticsService} from "../../statistics/StatisticsService";
import {UpdateAble} from "./UpdateAble";
import {GraphOptions} from "../../statistics/v2/GraphOptions";
import FormHeader from "./FormHeader";
import {Goal} from "./Goal";
import {copy, edit, Mode, register, view} from "../v3/Mode";
import RegisterResultFailureMessage from "./RegisterResult";
import X from "../../../images/X_2.png"
import {EventBackendService} from "./EventBackendService";
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";
import {User} from "../../model/User";
import {Organisation} from "../../model/Organisation";
import {hasRight} from "../../../utils/HasRight";
import AllEventsTable from "../../reports/v1/AllEventsTable";
import MobileGraphs from "../../graph/MobileGraphs";


const ignoredInMaxProgress = [
    "ryggstickbeta.comment",
    "delivery.comment",
    "delivery.countersign",
    "anticonception.comment"
];

interface props {
    actionName: string,
    actionVersion: number,
    actionType: string,
    dateFormat: string,
    actionService: ActionService,
    eventBackend: EventBackend,
    statisticsService: StatisticsService,
    user: User,
    userEmail?: string,
    currentOrganisation?: Organisation
}

interface state {
    action: Action | undefined,
    actionError: Error | undefined,
    device: string,
    edit: boolean,
    copy: boolean,
    view: boolean,
    errors: FieldError[],
    id?: string,
    showPopoverModal: boolean,
    event?: Event,
    mode: Mode,
    showFailureMessage: boolean,
    showSuccessMessage: boolean,
    progress: number,
    maxProgress: number,
    localMessages: string,
    currentlySaving: boolean
}

const localMessageDelay = 25;

class Register extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;
    amIMounted: boolean = false;
    fieldReferences: Map<string, React.ReactInstance> = new Map<string, React.ReactInstance>();
    statsRef: any;
    historyRef: any;

    constructor(props: props) {
        super(props);

        this.saveNG = this.saveNG.bind(this);
        this.updateNG = this.updateNG.bind(this);
        let mode = register();

        this.state = {
            action: undefined,
            actionError: undefined,
            device: DESKTOP,
            edit: false,
            copy: false,
            view: false,
            errors: [],
            showPopoverModal: false,
            mode: mode,
            showFailureMessage: false,
            showSuccessMessage: false,
            progress: 0,
            maxProgress: 100,
            localMessages: "",
            currentlySaving: false
        }
    }

    render(): ReactElement {
        if (this.state.actionError !== undefined) {
            const unknownActionMessage: string = TranslationService.translation("unknownActionMessage");
            return <div data-testid={"unknown action"}>{unknownActionMessage}</div>
        }

        if (this.state.action === undefined) {
            return <div data-testid={"no-action-spinner"} className={"spinner"}/>
        }

        const action: Action = this.state.action;
        const name: string = this.props.actionName;
        const version: number = this.props.actionVersion;
        const actionType: string = this.props.actionType;
        const dateFormat: string = this.props.dateFormat;
        this.statsRef = createRef();
        this.historyRef = createRef();
        const edit = (event: Event) => this.editEvent(event);
        const editGoal = (goal: Goal) => this.editGoal(goal);
        const copy = (event: Event) => this.copyEvent(event);
        const copyGoal = (goal: Goal) => this.copyGoal(goal);
        const deleteFun = (id: string) => this.delete(id);
        const viewEvent = (event: Event) => this.viewEvent(event);
        const device: string = this.state.device;
        const statisticsService = this.props.statisticsService;
        const eventBackend = this.props.eventBackend;
        const userEmail = this.props.userEmail;
        const formHeader = <FormHeader actionName={name} actionType={actionType}/>;

        const crfAirwayEr = 'crf-airway-er'; // Hack to remove graphs and latest for a crf

        let resultModal: React.JSX.Element = <div/>;
        const failureMessage: string[] = [
            "Det har skett ett fel med registreringen.",
            "Felet är vårt.",
            "Prova igen om en stund.",
            "Observera att registreringen inte har sparats!"
        ]

        if (this.state.showFailureMessage) {
            resultModal =
                <RegisterResultFailureMessage message={failureMessage} hideFailureMessage={this.hideFailureMessage}/>;
        }

        let snackbar = <div/>;
        if (this.state.showSuccessMessage) {
            const successfulMessage: string = TranslationService.translation("successful registration message")
            snackbar = <div onClick={() => this.closeSnackbar()}
                            className={"snackbar"}>
                {successfulMessage}
                <button className={"snackbar-button"}><img className={"snackbar-image"} height={12} src={X}
                                                           alt={"close toast"}/></button>
            </div>
        }

        let graphs;
        if (action.groupBy === undefined || name === crfAirwayEr) {
            graphs = <div/>;
        } else {
            const graphOptions: GraphOptions = {groupBy: action.groupBy};
            graphOptions.currentOrganisation = this.props.currentOrganisation;

            if (this.state.device === MOBILE) {
                graphs = <MobileGraphs ref={this.statsRef}
                                       actionName={name}
                                       actionVersion={version}
                                       showDescription={false}
                                       graphOptions={graphOptions}
                                       statisticsService={statisticsService}
                />;
            } else {
                graphs = <Graphs ref={this.statsRef}
                                 actionName={name}
                                 actionVersion={version}
                                 showDescription={false}
                                 graphOptions={graphOptions}
                                 statisticsService={statisticsService}
                />;
            }
        }

        const form = this.getForm(action, device, dateFormat, userEmail);

        const errors = this.getErrors();

        const buttons = this.getButtons(action);

        let history;
        if (hasRight(this.props.user, name, "event-history")) {
            history = <div aria-label={"latest events"}>
                <h4>{TranslationService.translation("latestEvents")}</h4>
                <AllEventsTable
                    user={this.props.user}
                    backend={eventBackend}
                    ref={this.historyRef}
                    actionName={name}
                    version={version}
                    editEvent={edit}
                    editGoal={editGoal}
                    copyEvent={copy}
                    copyGoal={copyGoal}
                    deleteEvent={deleteFun}
                    deleteGoal={deleteFun}
                    viewEvent={viewEvent}
                />
            </div>
        } else {
            if (name === crfAirwayEr) {
                history = <div/>
            } else {
                history = <History ref={this.historyRef}
                                   actionName={name}
                                   actionVersion={version}
                                   actionType={actionType}
                                   editEvent={edit}
                                   editGoal={editGoal}
                                   copyEvent={copy}
                                   copyGoal={copyGoal}
                                   deleteEvent={deleteFun}
                                   deleteGoal={deleteFun}
                                   viewEvent={viewEvent}
                                   backend={eventBackend}
                />;
            }
        }

        return <div>
            {formHeader}
            <div className={"container"}>
                {graphs}
                <div className={"row m-0 p-0 error-messages-register"}>
                    {this.state.localMessages}
                </div>
                {form}
                {errors}
                {buttons}
                {history}
                {resultModal}
                {snackbar}
            </div>
        </div>
    }

    private getForm(action: Action, device: string, dateFormat: string, userEmail: string | undefined): React.ReactFragment {
        if (action.frontendVersion === V3) {
            const src: Event | undefined = this.state.event;
            const mode: Mode = this.state.mode;
            let event: EventNG;

            if (src === undefined) {
                event = createEvent();
            } else {
                event = getEventForFrontend(src);
            }

            let key = JSON.stringify(mode);

            let user = {};
            if (this.props.user !== undefined) {
                user = this.props.user;
            }

            return <FormNG key={key}
                           action={action}
                           event={event}
                           mode={mode}
                           currentlySaving={this.state.currentlySaving}
                           save={this.saveNG}
                           update={this.updateNG}
                           user={user}
                           currentOrganisation={this.props.currentOrganisation}
                           device={device}
                           backend={this.props.eventBackend}
            />
        } else {
            return <Form action={action}
                         fieldReferences={this.fieldReferences}
                         device={device}
                         dateFormat={dateFormat}
                         showPopoverModal={this.state.showPopoverModal}
                         flipPopover={() => this.flipPopover()}
                         userEmail={userEmail}
            />;
        }
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        const name = this.props.actionName;
        const version = this.props.actionVersion;
        const type = this.props.actionType;

        const prevName = prevProps.actionName;
        const prevVersion = prevProps.actionVersion;
        const prevType = prevProps.actionType;

        if (name !== prevName || version !== prevVersion || type !== prevType) {
            this.setAction();
        }
    }

    componentDidMount() {
        this.amIMounted = true;
        window.addEventListener('resize', this.onResize);
        this.setAction();
        let device = Register.getDevice();
        this.setState({device: device});
    }

    private setAction() {
        const name = this.props.actionName;
        const version = this.props.actionVersion;
        const type = this.props.actionType;
        const actionService = this.props.actionService;
        const actionPromise: Promise<Action | Error> = actionService.getAction(name, version, type);

        actionPromise.then((action: Action | Error) => {
            if (this.amIMounted) {
                if (!('reason' in action)) {
                    this.setState({action: action});
                } else {
                    this.setState({actionError: action});
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    componentWillUnmount() {
        this.amIMounted = false;
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        let device = Register.getDevice();
        const currentDevice = this.state.device;

        if (device !== currentDevice) {
            this.setState({device: device});
        }

        //TODO, update UserSessionUtilService
    }

    static getDevice(): string { //TODO move this so usersessionutilservice
        const width: number = window.innerWidth;
        let device: string;
        if (width >= 1200) {
            device = DESKTOP;
        } else {
            device = MOBILE;
        }
        return device;
    }

    private getErrors(): React.ReactFragment {
        const errors = this.state.errors;
        if (errors.length > 0) {
            return <div>
                {errors.map((error: FieldError) => {
                        const name = error.name;
                        const errorDescription = error.error;
                        const ariaLabel = name + " " + errorDescription;
                        const message = TranslationService.translation(name) + " " + TranslationService.translation(errorDescription);
                        return <div className="errorFont" key={uuidv4()}
                                    aria-label={ariaLabel}>
                            {message}
                        </div>
                    }
                )}
            </div>
        } else {
            return <div/>
        }
    }

    private getButtons(action: Action): React.ReactFragment {
        if (action.frontendVersion === V3) {
            // No need to create any buttons in register for v3.
            // This method should die when a rewrite and migration is done.
            const ariaLabel = 'Buttons are created in the v3 form';
            return <div aria-label={ariaLabel}/>;
        }

        const edit: boolean = this.state.edit;
        const view: boolean = this.state.view;

        if (view) {
            const cancel: string = TranslationService.translation("cancel");

            return <div className={"d-flex justify-content-end pt-3 m-0"}>
                <button id={"cancelButton"}
                        aria-label={"cancel button"}
                        className={"btn"}
                        onClick={() => this.cancel(this.fieldReferences)}>
                    {cancel}
                </button>
            </div>;
        }

        if (edit) {
            const cancel: string = TranslationService.translation("cancel");
            const update: string = TranslationService.translation("update");

            return <div className={"d-flex justify-content-end pt-3 m-0"}>
                <button id={"cancelButton"}
                        aria-label={"cancel button"}
                        className={"btn"}
                        onClick={() => this.cancel(this.fieldReferences)}>
                    {cancel}
                </button>
                <button id={"submitButton"}
                        aria-label={"update button"}
                        className={"ml-1 btn btn-save btn-submitNewReg"}
                        onClick={() => this.update(this.fieldReferences)}>
                    {update}
                </button>
            </div>;
        }

        const save: string = TranslationService.translation("save");
        return <div className={"d-flex justify-content-end pt-3 m-0"}>
            <button id={"submitButton"}
                    aria-label={"save button"}
                    className={"btn btn-save btn-submitNewReg"}
                    onClick={() => this.save(this.fieldReferences)}>
                {save}
            </button>
        </div>;
    }

    async saveNG(event: EventNG, action: Action): Promise<void> {
        this.setState({currentlySaving: true})
        const {progress, maxProgress} = this.state;
        let eventForBackend: Event = getEventForBackend(action, event);

        if (this.props.currentOrganisation !== undefined) {
            eventForBackend.organisationId = this.props.currentOrganisation.organisationId;
        } else {
            eventForBackend.organisationId = undefined;
        }

        if (progress && maxProgress) {
            const ci: number = (progress / maxProgress) * 100;
            if (ci) {
                eventForBackend.completenessIndex = +ci.toFixed(2);
            }
        }

        const path = "/api/v2/event/register";
        const url = EventBackendService.getUrl2(path);

        const postResult = await this.context.post(url, JSON.stringify(eventForBackend), {
            success: `Sparat`,
            failure: `Fel uppstod`
        });

        if (!postResult.success) {
            const temporaryErrorMessage = "Det har inträffat ett fel och din registrering blev ej sparad. Det är vårt fel, vi har fått ett meddelande och vi kommer antagligen kontakta dig för felsökning. Du kan pröva att registrera igen - det är ingen risk för dubbelregistrering.\n\n"
            try {
                const errorFields = JSON.stringify(postResult.data.errorFields);
                this.setState({localMessages: temporaryErrorMessage + errorFields})
            } catch (e) {
                this.setState({localMessages: temporaryErrorMessage + postResult.message})
            }

            this.removeLocalMessageAfterDelay();
        }

        const history: UpdateAble = this.historyRef.current;
        this.setState({currentlySaving: false})
        history.update();
        this.updateStats();
        this.scrollToTop();
        this.emptyProgressBar();
    }

    private removeLocalMessageAfterDelay() {
        setTimeout(() => {
            this.setState({localMessages: ""})
            return;
        }, localMessageDelay * 1000);
    }

    async updateNG(event: EventNG, action: Action): Promise<void> {
        this.setState({currentlySaving: true})
        const eventForBackend: Event = getEventForBackend(action, event);

        const path = "/api/v2/event/update";
        const url = EventBackendService.getUrl2(path);

        const putResult = await this.context.put(url, JSON.stringify(eventForBackend), {
            success: `Uppdaterat`,
            failure: `Fel uppstod vid uppdatering`
        });

        if (!putResult.success) {
            const temporaryErrorMessage = "Det har inträffat ett fel och din registrering blev ej sparad. Det är vårt fel, vi har fått ett meddelande och vi kommer antagligen kontakta dig för felsökning. Du kan pröva att registrera igen - det är ingen risk för dubbelregistrering.\n\n"
            try {
                const errorFields = JSON.stringify(putResult.data.errorFields);
                this.setState({localMessages: temporaryErrorMessage + errorFields})
            } catch (e) {
                this.setState({localMessages: temporaryErrorMessage + putResult.message})
            }

            this.removeLocalMessageAfterDelay();
        }

        const history: UpdateAble = this.historyRef.current;
        history.update();
        this.updateStats();
        this.scrollToTop();
        this.emptyProgressBar();
        this.setState({currentlySaving: false})
    }

    save(fieldReferences: Map<string, any>) {
        const name: string = this.props.actionName;
        const version: number = this.props.actionVersion;
        const actionType: string = this.props.actionType;
        const backend = this.props.eventBackend;

        if (actionType === GOAL) {
            this.saveGoal(name, version, fieldReferences, backend);
        } else {
            this.saveEvent(name, version, fieldReferences, backend);
        }
    }

    private saveGoal(name: string, version: number, fieldReferences: Map<string, any>, backend: EventBackend) {
        const result: Goal | FieldError[] = getGoal(name, version, fieldReferences);

        if (instanceOfEventOrGoal(result)) {
            const goal: Goal = result as Goal;
            backend.saveGoal(goal)
                .then((eventValidation: string) => {
                    console.log("Goal validation", eventValidation);
                    const history: UpdateAble = this.historyRef.current;
                    history.update();
                    this.updateStats();
                });

            clearForm(fieldReferences);
            this.setState({errors: []});
            this.scrollToTop();
        } else {
            const errors: FieldError[] = result as FieldError[];
            this.setState({errors: errors});
        }
    }

    private saveEvent(name: string, version: number, fieldReferences: Map<string, any>, backend: EventBackend) {
        const result: Event | FieldError[] = getEvent(name, version, fieldReferences);

        if (instanceOfEventOrGoal(result)) {
            let event: Event = result as Event;

            if (this.props.currentOrganisation !== undefined) {
                event.organisationId = this.props.currentOrganisation.organisationId;
            } else {
                event.organisationId = undefined;
            }

            backend.saveEvent(event)
                .then((eventValidation: string) => {
                    if (name !== 'crf-airway-er') {
                        const history: UpdateAble = this.historyRef.current;
                        history.update();
                        this.updateStats();
                    }
                    clearForm(fieldReferences);
                    this.setState({errors: [], progress: 0, maxProgress: 100});
                    this.showSuccessSnackbar()
                    this.scrollToTop();
                    this.emptyProgressBar();
                }).catch((er) => {
                console.log(er);
                this.setState({
                        showFailureMessage: true
                    }
                );
            })


        } else {
            const errors: FieldError[] = result as FieldError[];
            this.setState({errors: errors});
        }
    }

    cancel(fieldReferences: Map<string, any>) {
        this.setState({
            edit: false,
            view: false
        }, () => {
            clearForm(fieldReferences);
        });
    }

    update(fieldReferences: Map<string, any>) {
        const name = this.props.actionName;
        const version = this.props.actionVersion;
        const actionType = this.props.actionType;
        const id = this.state.id;
        const backend = this.props.eventBackend;

        if (actionType === GOAL) {
            this.updateGoal(name, version, fieldReferences, id, backend);
        } else {
            this.updateEvent(name, version, fieldReferences, id, backend);
        }
    }

    private updateGoal(name: string, version: number, fieldReferences: Map<string, any>, id: string | undefined, backend: EventBackend) {
        const result: Goal | FieldError[] = getGoal(name, version, fieldReferences, id);
        if (instanceOfEventOrGoal(result)) {
            const goal: Goal = result as Goal;
            backend.updateGoal(goal)
                .then((validation) => {
                    console.log("Validation", validation); // visa upp felet vid 400 från backend
                    const history: UpdateAble = this.historyRef.current;
                    history.update();
                    this.updateStats();
                });

            clearForm(fieldReferences);
            this.setState({edit: false});
            this.scrollToTop();
        } else {
            const errors: FieldError[] = result as FieldError[];
            this.setState({errors: errors});
        }
    }

    private updateEvent(name: string, version: number, fieldReferences: Map<string, any>, id: string | undefined, backend: EventBackend) {
        const result: Event | FieldError[] = getEvent(name, version, fieldReferences, id);
        if (instanceOfEventOrGoal(result)) {
            let event: Event = result as Event;

            backend.updateEvent(event)
                .then((eventValidation) => {
                    console.log("Event Validation", eventValidation); // visa upp felet vid 400 från backend
                    const history: UpdateAble = this.historyRef.current;
                    history.update();
                    this.updateStats();
                });

            clearForm(fieldReferences);
            this.setState({edit: false});
            this.emptyProgressBar();
            this.scrollToTop();
        } else {
            const errors: FieldError[] = result as FieldError[];
            this.setState({errors: errors});
        }
    }

    editEvent(event: Event): void {
        let frontendVersion: string | undefined = undefined;
        if (this.state.action !== undefined) {
            frontendVersion = this.state.action.frontendVersion;
        }

        if (frontendVersion !== undefined && frontendVersion === V3) {
            this.setState({
                mode: edit(),
                event: event
            });
        } else {
            const id = event.id;
            const fieldReferences = this.fieldReferences;
            this.setState({
                edit: true,
                id: id
            }, () => {
                addEventValuesToForm(event, fieldReferences);
            });
        }

        this.scrollToTop();
    }

    editGoal(goal: Goal): void {
        const id = goal.id;
        const fieldReferences = this.fieldReferences;
        this.setState({
            edit: true,
            id: id
        }, () => {
            addGoalValuesToForm(goal, fieldReferences);
        });
        this.scrollToTop();
    }

    copyEvent(event: Event): void {
        let frontendVersion: string | undefined = undefined;
        if (this.state.action !== undefined) {
            frontendVersion = this.state.action.frontendVersion;
        }

        if (frontendVersion !== undefined && frontendVersion === V3) {
            this.setState({
                mode: copy(),
                event: event
            });
        } else {
            const fieldReferences = this.fieldReferences;
            addEventValuesToForm(event, fieldReferences);
        }

        this.scrollToTop();
    }

    copyGoal(goal: Goal): void {
        const fieldReferences = this.fieldReferences;
        addGoalValuesToForm(goal, fieldReferences);
        this.scrollToTop();
    }


    shouldIgnoreElement = (elem: Element | ChildNode | null): boolean => {
        if (elem) {
            if (ignoredInMaxProgress.includes((elem as Element).id)) {
                return true;
            }
            if (elem.hasChildNodes()) {
                return this.shouldIgnoreElement(elem.firstChild);
            }
        }
        return false;
    }

    delete(id: string): void {
        const actionType = this.props.actionType;
        const backend = this.props.eventBackend;

        if (actionType === GOAL) {
            backend.deleteGoal(id)
                .then(() => {
                    const history: UpdateAble = this.historyRef.current;
                    history.update();
                    this.updateStats();
                });
        } else {
            backend.deleteEvent(id)
                .then(() => {
                    const history: UpdateAble = this.historyRef.current;
                    history.update();
                    this.updateStats();
                });
        }
    }

    hideFailureMessage = () => {
        this.setState({showFailureMessage: false})
    }


    flipPopover(): void {
        this.setState({showPopoverModal: !this.state.showPopoverModal});
    }

    viewEvent(event: Event): void {
        let frontendVersion: string | undefined = undefined;
        if (this.state.action !== undefined) {
            frontendVersion = this.state.action.frontendVersion;
        }

        if (frontendVersion !== undefined && frontendVersion === V3) {
            this.setState({
                mode: view(),
                event: event
            });
        } else {
            const fieldReferences = this.fieldReferences;
            this.setState({view: true}, () => {
                addEventValuesToForm(event, fieldReferences);
            });
        }

        this.scrollToTop();
    }

    private updateStats() {
        const stats: UpdateAble = this.statsRef.current;
        if (stats !== null) {
            stats.update();
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    private showSuccessSnackbar() {
        this.setState({showSuccessMessage: true});

        let displayTimeMs = 5000; // safety flip back to false if the user fails to click on the snackbar
        setTimeout(() => {
            this.closeSnackbar();
        }, displayTimeMs)
    }

    private closeSnackbar() {
        this.setState({showSuccessMessage: false});
    }

    private emptyProgressBar() {
        this.setState({maxProgress: 100, progress: 0})
    }
}

export default Register;
