import React from 'react';
import {BackendContext, BackendInterface, Result} from "../../../../infra/BackendContext";

interface Action {
    name: string,
    translatedName: string
}

interface Props {
}

interface State {
    actions: Action[],
    selected: Set<string>,
    message: string | undefined
}

class CompletenessIndex extends React.Component<Props, State> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: Readonly<Props> | Props) {
        super(props);

        this.state = {
            actions: [],
            selected: new Set(),
            message: undefined
        }
    }

    render() {
        const actions: React.JSX.Element[] = this.getCheckboxes();
        const reCalculateButton: React.JSX.Element = this.getReCalculateButton();
        const message: string | undefined = this.state.message;
        let content: React.JSX.Element;

        if (message === undefined) {
            content = <div className={'row'}>
                <div className={'col'}>
                    {actions}
                </div>
                <div className={'col'}>
                    {reCalculateButton}
                </div>
            </div>;
        } else {
            content = <div>{message}</div>;
        }

        return (
            <div>
                <h2>Completeness index</h2>
                {content}
            </div>
        );
    }

    async componentDidMount() {
        await this.getActions();
    }

    private async getActions() {
        const url: string = '/api/v1/backoffice/actions';
        const payload: Result = await this.context.get(url, {
            success: '',
            failure: ''
        });

        if (payload.success) {
            const actions: Action[] = payload.data.actions;

            this.setState({
                actions: actions
            })
        }
    }

    private getCheckboxes(): React.JSX.Element[] {
        const allSelected: Set<string> = this.state.selected;

        return this.state.actions.map((value: Action, index: number) => {
            const name: string = value.name;
            let translatedName: string = value.translatedName;
            const selected: boolean = allSelected.has(name);

            return <div key={name + '-' + index}
                        onClick={() => this.selectAction(name)}>
                <input checked={selected}
                       onChange={() => {
                       }}
                       type={"checkbox"}/>
                {translatedName}
            </div>
        });
    }

    private selectAction(name: string) {
        const selected: Set<string> = this.state.selected;

        if (selected.has(name)) {
            selected.delete(name);
        } else {
            selected.add(name);
        }

        this.setState({selected: selected})
    }

    private getReCalculateButton() {
        return <button type={"submit"}
                       aria-label={'recalculate'}
                       onClick={() => this.requestReCalculation()}>
            Recalculate Completeness indexes
        </button>
    }

    private async requestReCalculation() {
        console.log('Requestred recalculation');
        const selected: Set<string> = this.state.selected;

        const url: string = '/api/v1/backoffice/actions';
        const payload: { actions: string[] } = {
            actions: []
        }

        selected.forEach((value: string) => {
            payload.actions.push(value);
        });

        await this.context.post(url, JSON.stringify(payload), {
            success: "",
            failure: ""
        });

        const message = 'Started recalculating the completeness indexes';
        this.setState({
            selected: new Set(),
            message: message
        });
    }
}

export default CompletenessIndex;
