import React, {Component} from 'react';
import "./SignupPage.css";
import TranslationService from "../../infra/TranslationService";
import PasswordInputField from "../fields/v2/PasswordInputField";


const SIGNUPAPI = process.env.REACT_APP_LOCALHOST + '/api/v1/signup';

class SignupPage extends Component {
    constructor(props) {
        super(props);
        this.state.signUpToken = this.props.match.params.token;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state.password = "";
    }

    state = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: "",
        language: "",
        inviter: "",
        signUpToken: "",
        roles: [],
        hsaid: "",
        displayWarning: false,
        displayErrors: false,
        errors: []
    };

    componentDidMount() {
        this.setState({phone: "123 - 1234567"});

        let xhr = new XMLHttpRequest();
        let url = SIGNUPAPI.concat("/").concat(this.state.signUpToken);
        xhr.open("GET", url, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let response = JSON.parse(xhr.responseText);
                this.setState({email: response.email});
                this.setState({inviter: response.inviter});
                this.setState({organisation: response.organisation});
                this.setState({language: "sv_SE"});
                this.setState({roles: response.roles});
            }
        };
        xhr.send();
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    selectRole = (e, roleId) => {
        this.setState(
            this.state.roles.filter(role => role.roleId === roleId).map(role => role.selected = !role.selected)
        );
    };

    handleSubmit(event) {
        event.preventDefault();
        let data = JSON.stringify(this.state);

        //TODO byt till props med redirect
        localStorage.setItem("email", this.state.email);
        localStorage.setItem("password", this.state.password);

        fetch(SIGNUPAPI, {
            method: 'POST',
            body: data,
        }).then(() => {
            //TODO byt till redirect
            window.location = "/";
        }).catch(console.log);
    }

    render() {
        let isInvited = !(this.state.inviter === undefined);

        return (
            <React.Fragment>
                <div className={"mainContainer container"}>
                    <div className={"col pt-2"}>
                        <div className={"row pb-2"}>
                            <div className={"col"}/>
                            <div className={"col"}>
                                {TranslationService.translation("mandatory.fields,in.form")}
                            </div>
                        </div>

                        {isInvited ? <div className={"row pb-2"}>

                            <div className={"col text-right"}>
                                Inviter:
                            </div>

                            <div className={"col"}>
                                <input name={"inviter"}
                                       type={"text"}
                                       readOnly={true}
                                       defaultValue={this.state.inviter}
                                />
                            </div>
                        </div> : ""}


                        <div className={"row pb-2"}>
                            <div className={"col text-right"}>
                                Email:
                            </div>

                            <div className={"col"}>
                                <div>{this.state.email}</div>
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col text-right"}>
                                Förnamn: *
                            </div>
                            <div className={"col"}>
                                <input name={"firstName"}
                                       type={"text"}
                                       required={true}
                                       placeholder={this.state.firstName}
                                       onChange={e => this.onChange(e)}
                                />
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col text-right"}>
                                Efternamn: *
                            </div>
                            <div className={"col"}>
                                <input name={"lastName"}
                                       type={"text"}
                                       required={true}
                                       placeholder={this.state.lastName}
                                       onChange={e => this.onChange(e)}
                                />
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col text-right"}>
                                Lösenord: *
                            </div>
                            <div className={"col"}>
                                <PasswordInputField
                                    errors={this.state.errors}
                                    displayErrors={this.state.displayErrors}
                                    mandatory={true}
                                    password={this.state.password}
                                    update={(value) => this.updatePassword(value)}/>
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col text-right"}>
                                Telefon:
                            </div>
                            <div className={"col"}>
                                <input type="tel"
                                       name="phone"
                                       placeholder={this.state.phone}
                                       onChange={e => this.onChange(e)}
                                />
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col text-right"}>
                                Språk:
                            </div>
                            <div className={"col"}>
                                <select name={"language"}
                                        required={true}
                                        onChange={e => this.onChange(e)}>

                                    <option value={"sv_SE"}>Svenska</option>
                                </select>
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col text-right"}>
                                Område:
                            </div>
                            <div className={"col"}>

                                {this.state.roles.map(role => (
                                    <ul style={{paddingLeft: 0}} key={role.roleId}>
                                        {
                                            !!role.mandatory ? this.putDefaultSelected(role.roleId) : ""
                                        }
                                        <input
                                            name={"role"}
                                            type={"checkbox"}
                                            checked={!!role.selected}
                                            required={!!role.mandatory}
                                            value={role.roleId}
                                            onChange={event => this.selectRole(event, role.roleId)}/>
                                        <span
                                            style={{paddingLeft: 10}}>
                                    {
                                        role.translatedRoleName
                                    }
                                </span>
                                    </ul>
                                ))}
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col text-right"}>
                                HSA-ID:
                            </div>
                            <div className={"col"}>
                                <input
                                    name={"hsaid"}
                                    type={"text"}
                                    onChange={e => this.onChange(e)}/>
                            </div>
                        </div>
                        <div className={"row pb-2"}>
                            <div className={"col"}/>
                            <div className={"col"}>HSA-ID används ibland för att hitta kollegor.</div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col"}/>
                            <div className={"col text-center"}>
                                <button
                                    value={"Submit"}
                                    id={"signupButton"}
                                    className={"btn btn-signup"}
                                    onClick={(e) => this.verifyPassword(e)}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    putDefaultSelected(roleId) {
        this.state.roles.filter(role => role.roleId === roleId).map(role => role.selected = true);
    }

    updatePassword(inc) {
        inc.preventDefault();
        const incoming = inc.currentTarget.value
        const validation = PasswordInputField.adheresToPasswordRestrictions(incoming);
        this.setState({errors: validation.errors});
        this.setState({password: incoming})
    }

    verifyPassword(e) {
        e.preventDefault();
        const validation = PasswordInputField.adheresToPasswordRestrictions(this.state.password)
        if (!validation.valid) {
            this.setState({displayErrors: true, errors: validation.errors});
        } else {
            this.handleSubmit(e);
        }
    }
}

export default SignupPage;
