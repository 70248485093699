import React, {Component} from 'react';
import './StatisticsExperiencePage.css';
import arrowDown from '../../../images/arrowDown.png';
import arrowUp from '../../../images/arrowUp.png';
import C3Wrapper from "../../graph/C3Wrapper";
import {getSecurityToken} from "../../../infra/SecurityToken";
import TranslationService from "../../../infra/TranslationService";
import {hasRight} from "../../../utils/HasRight";
import ExportEventsButton from "../../splashpage/ExportEventsButton";

const EXPERIENCE_API = process.env.REACT_APP_LOCALHOST + "/api/v1/experience";

function fetchStatistics(parent, actionName, version) {
    const url = EXPERIENCE_API + "/" + actionName + "/" + version;
    fetch(url, {
        headers: {
            'X-Custom-header': getSecurityToken()
        },
        credentials: 'include'
    })
        .then(res => res.json())
        .then((data) => {
            parent.setState({dataExp: data});
            parent.setState({graph: data.graph});
            parent.setState({errorMessage: data.errorMessage})
        }).catch(console.log);
}

class ExperienceDetailsPage extends Component {
    constructor(props) {
        super(props);
        this.updateGraphWithData = this.updateGraphWithData.bind(this);
    }

    state = {
        dataExp: "",
        graph: "",
        expandAbsence: "+",
        errorMessage: ""
    };

    componentDidMount() {
        const actionName = this.props.actionName;
        const version = this.props.actionVersion;
        fetchStatistics(this, actionName, version);
    }

    render() {
        const showSummaryText = TranslationService.translation("show goals")

        let graph;
        if (this.state.graph === "") {
            graph = "";
        } else {
            graph = <C3Wrapper id={"chart_exp"}
                               data={this.state.graph.data}
                               axis={this.state.graph.axis}/>;
        }

        const actionName = this.props.actionName;
        const version = this.props.actionVersion;
        let hasExportRight = hasRight(this.props.user, actionName, "export-events");
        const exportButton = <div className={"btn-save d-flex justify-content-end pt-3 m-0"}>
            <ExportEventsButton hasExportRight={hasExportRight}
                                buttonText={"Ladda ner din data"}
                                showFailureMessage={this.showFailureMessage}
                                actionName={actionName}
                                version={version}
                                scope={'user'}
            />
        </div>

        const summaryButton = <div className={"col text-right"}>
            <button onClick={this.props.showSummary}>
                {showSummaryText}
            </button>
        </div>;

        return (
            <div className={"col pt-2"}>
                <div id={"react-c3Experience"}>
                    {graph}
                </div>
                <div className={"row"}>
                    {summaryButton}
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        {exportButton}
                    </div>
                </div>

                {this.drawDataTable()}
            </div>
        );
    }

    drawDataTable() {
        return <>
            {
                this.state.errorMessage !== undefined ?
                    <div className={"col text-center"}>{this.state.errorMessage}</div>
                    :
                    this.state.dataExp !== "" ? <div className={"container pt-2"}>
                            {
                                this.drawToplevelHeader()
                            }
                            <div className={"row"} id={this.state.dataExp.name}>
                                {
                                    this.drawCategories()
                                }
                            </div>
                            <hr/>
                            <div className={"row"}>
                                {
                                    this.drawSummaryRow()
                                }
                            </div>
                        </div>
                        :
                        ""
            }
        </>;
    }

    drawCategories() {
        return <>
            {
                this.state.dataExp.categories.map(row => {
                    let rowId = row.name;
                    return (
                        <React.Fragment key={row.name}>
                            {
                                this.drawCategoryRow(row, rowId)
                            }
                            <div className={"col-sm-12 collapse"} id={rowId}>
                                {
                                    this.drawCollapsedExperienceSummaries(row)
                                }
                            </div>

                        </React.Fragment>
                    )
                })
            }
        </>;
    }

    drawCollapsedExperienceSummaries(row) {
        return <>
            {
                row.experienceSummaries.map(level2 => {
                    let rowId = level2.name + level2.experiences.length.toString();
                    return (
                        <React.Fragment key={rowId}>
                            {
                                this.drawExperienceSummaryRows(level2, row, rowId)
                            }
                            <div className={"row"}>
                                <div className={"col-sm-12 collapse"} id={rowId}>
                                    <div className={"container pl-4 pr-1"}>
                                        {
                                            this.drawCollapsedExperiences(level2)
                                        }
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })
            }
        </>;
    }

    drawCollapsedExperiences(level2) {
        return <>
            {
                this.drawExperiencesHeader()
            }
            {
                level2.experiences.map(level3 => {
                    return (
                        <React.Fragment key={level3.experienceID}>
                            {
                                this.drawExperienceRows(level3)
                            }
                            <div className={"row"}>
                                <div className={"col-sm-12 collapse"} id={level3.experienceID}>
                                    {this.drawDayHeader()}
                                    {
                                        this.drawDayRows(level3)
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })

            }
        </>;
    }

    drawCategoryRow(row, rowId) {
        return <>
            <div className={"container m-0 kategori"}>
                <div className={"row"}>
                    <div className={"col-sm-2"}>{row.nameTranslated}</div>
                    <div className={"col-sm-2"}>{row.sos}</div>
                    <div className={"col-sm-2"}>{row.actual}</div>
                    <div className={"col-sm-2"}>{row.valid}</div>
                    <div className={"col-sm-1"}>{row.invalid}</div>
                    <div className={"col-sm-2"}>{row.invalid + row.sos}</div>
                    <div className={"col-sm-1"}>
                        {
                            row.experienceSummaries.length > 0 ?
                                <img className={"btn btn-exp-expand"}
                                     alt={"Expand button"}
                                     height={22}
                                     src={arrowDown}
                                     data-toggle={"collapse"} data-target={"#" + rowId}
                                     onClick={event => this.experienceOnClick(event, row.name, "kategori")}>
                                </img>
                                :
                                ""
                        }
                    </div>
                </div>
            </div>
        </>;
    }

    drawExperienceSummaryRows(level2, row, rowId) {
        return <div className={"row experienceSummary"}>
            <div className={"col-sm-2  pl-4"}>{level2.nameTranslated}</div>
            <div className={"col-sm-2"}>{level2.sos}</div>
            <div className={"col-sm-2"}>{level2.actual}</div>
            <div className={"col-sm-2"}>{level2.valid}</div>
            <div className={"col-sm-1"}>{level2.invalid}</div>
            <div className={"col-sm-2"}>{level2.invalid + level2.sos}</div>
            <div className={"col-sm-1"}>
                {
                    level2.experiences.length > 0 ?
                        <img className={"btn btn-exp-expand"} data-toggle={"collapse"}
                             alt={"Expand button"}
                             height={22}
                             src={arrowDown}
                             data-target={"#" + rowId}
                             onClick={event => this.experienceOnClick(event, level2.name, "experienceSummary")}>
                        </img>
                        :
                        ""
                }
            </div>
        </div>;
    }

    drawExperienceRows(level3) {
        return <div className={"row experiences"}>
            <div className={"col-sm-1"}>{level3.experienceDetailedName}</div>
            <div className={"col-sm-2 text-right"}>{level3.from}</div>
            <div className={"col-sm-2 text-right"}>{level3.to}</div>
            <div className={"col-sm-1"}>{level3.sos}</div>
            <div className={"col-sm-1"}>{level3.actual}</div>
            <div className={"col-sm-1"}>{level3.valid}</div>
            <div className={"col-sm-1"}>{level3.invalid}</div>
            <div className={"col-sm-1"}>{level3.sos + level3.invalid}</div>
            <div className={"col-sm-1"}>{level3.comment}</div>
            <div className={"col-sm-1"}>
                {
                    level3.experienceDays.length > 0 ?
                        <img data-toggle={"collapse"}
                             className={"btn btn-exp-expand"}
                             alt={"Expand button"}
                             height={22}
                             src={arrowDown}
                             data-target={"#" + level3.experienceID}
                             onClick={event => this.experienceOnClick(event, level3.experienceID, "experiences")}>
                        </img>
                        :
                        ""
                }
            </div>
        </div>;
    }

    drawDayRows(level3) {
        return <>
            {
                level3.experienceDays.map(level4 => {
                    return (
                        <div className={"row"} key={level4.date + level3.experienceID}>
                            <div className={"col-sm-2 "}>{level4.date}</div>
                            <div className={"col-sm-2 "}>{level4.reasonTranslated}</div>
                            <div className={"col-sm-2 "}>{level4.sos === 0 ? "" : level4.sos}</div>
                            <div className={"col-sm-2 "}>{level4.actual === 0 ? "" : level4.actual}</div>
                            <div className={"col-sm-2 "}>{level4.valid === 0 ? "" : level4.valid}</div>
                            <div className={"col-sm-2 "}>{level4.invalid === 0 ? "" : level4.invalid}</div>
                        </div>
                    );
                })
            }
        </>;
    }

    drawExperiencesHeader() {
        return <div className={"row graybar experiences pt-2"}>
            <div className={"col-sm-1"}><h6>Placering</h6></div>
            <div className={"col-sm-2 text-right"}><h6>Från</h6></div>
            <div className={"col-sm-2 text-right"}><h6>Till</h6></div>
            <div className={"col-sm-1"}><h6>SoS</h6></div>
            <div className={"col-sm-1"}><h6>Faktisk</h6></div>
            <div className={"col-sm-1"}><h6>Giltig</h6></div>
            <div className={"col-sm-1"}><h6>Ogiltig</h6></div>
            <div className={"col-sm-1"}><h6>Totalt</h6></div>
            <div className={"col-sm-1"}><h6>Kommentar</h6></div>
            <div className={"col-sm-1"}/>
        </div>;
    }

    drawDayHeader() {
        return <div className={"row pt-2 graybar"}>
            <div className={"col-sm-2 "}><h6>Datum</h6></div>
            <div className={"col-sm-2 "}><h6>Orsak</h6></div>
            <div className={"col-sm-2 "}><h6>Sos</h6></div>
            <div className={"col-sm-2 "}><h6>Faktisk</h6></div>
            <div className={"col-sm-2 "}><h6>Giltig</h6></div>
            <div className={"col-sm-2 "}><h6>Ogiltig</h6></div>
        </div>;
    }

    drawToplevelHeader() {
        return <div className={"row"}>
            <div className={"col-sm-2"}><h5>Kategori</h5></div>
            <div className={"col-sm-2"}><h5>SoS</h5></div>
            <div className={"col-sm-2"}><h5>Faktisk</h5></div>
            <div className={"col-sm-2"}><h5>Giltig</h5></div>
            <div className={"col-sm-1"}><h5>Ogiltig</h5></div>
            <div className={"col-sm-2"}><h5>Totalt</h5></div>
            <div className={"col-sm-1"}/>

        </div>;
    }

    drawSummaryRow() {
        let total = this.state.dataExp.total;
        return <>
            {
                <React.Fragment>
                    <div className={"container m-0"}>
                        <div className={"row"}>
                            <div className={"col-sm-2"}><b>Subtotalt: </b></div>
                            <div className={"col-sm-2"}>{total.staticSos}</div>
                            <div className={"col-sm-2"}>{total.actualStatic}</div>
                            <div className={"col-sm-2"}>{total.staticValid}</div>
                            <div className={"col-sm-1"}>{total.staticInvalid}</div>
                            <div className={"col-sm-2"}>{total.staticSos + total.staticInvalid}</div>
                            <div className={"col-sm-1"}>
                                <button
                                    className={"btn btn-exp-summary"}
                                    data-toggle={"collapse"}
                                    data-target={"#summaryTotal"}
                                    onClick={(e) => this.flipActive(e)}>
                                    {this.state.expandAbsence}
                                </button>
                            </div>
                        </div>
                        <div className={"row pl-4"}>
                            <div className={"col-s-12 collapse"} id={"summaryTotal"}>
                                Dessutom har du partiella frånvarodagar enligt följande:
                                giltig=<b>{total.allocatedValidAbsenceDays}</b>,
                                ogiltig=<b>{total.allocatedInvalidAbsenceDays}</b>.
                                Av totalt möjliga närvarodagar :<b>{total.possibleAbsenceDays}</b>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm-2"}><b>Totalt:</b></div>
                            <div className={"col-sm-2"}>{total.staticSos - total.allocatedInvalidAbsenceDays}</div>
                            <div
                                className={"col-sm-2"}>{total.actualStatic - (total.allocatedValidAbsenceDays + total.allocatedInvalidAbsenceDays)}</div>
                            <div className={"col-sm-2"}>{total.staticValid + total.allocatedValidAbsenceDays}</div>
                            <div className={"col-sm-1"}>{total.staticInvalid + total.allocatedInvalidAbsenceDays}</div>
                            <div
                                className={"col-sm-2"}>{total.staticSos + total.staticInvalid + total.allocatedInvalidAbsenceDays}</div>
                            <div className={"col-sm-1"}/>
                        </div>
                    </div>
                </React.Fragment>
            }
        </>;
    }

    fadeInChosen(name) {
        let elements = document.getElementsByClassName(name);
        if (elements !== null) {
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.remove("faded");
            }
        }
    }

    fadeOutChosen(name) {
        let elements = document.getElementsByClassName(name);
        if (elements !== null) {
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.add("faded");
            }
        }
    }

    setButtonToActive(event) {
        event.target.classList.add("active-exp-button");
    }

    setButtonToInactive(event) {
        event.target.classList.remove("active-exp-button");
    }

    experienceOnClick(event, identifier, level) {
        if (event.target.src === arrowDown) {
            this.localUpdateGraph(identifier);
            this.fadeOutChosen(level);
            this.setButtonToActive(event);
            event.target.src = arrowUp;
        } else {
            this.localUpdateGraphParent(identifier);
            this.fadeInChosen(level);
            this.setButtonToInactive(event);
            event.target.src = arrowDown;
        }
    }

    updateGraphWithData(data) {

        if (data !== "") {
            const graphString = JSON.stringify(this.state.graph);
            let updatedGraph = JSON.parse(graphString);

            let x = [];
            x.push("x");
            let actual = [];
            actual.push("actual");
            let otherRotation = [];
            otherRotation.push("otherRotation");
            let valid = [];
            valid.push("valid");
            let invalid = [];
            invalid.push("invalid");

            if (data[0].experiences !== undefined) {
                //först nivån (experienceSummary)
                for (let i = 0; i < data.length; i++) {
                    x.push(data[i].nameTranslated);
                    actual.push(data[i].actual);
                    const akp = data[i].otherRotation;
                    valid.push(data[i].valid - akp);
                    otherRotation.push(akp);
                    invalid.push(data[i].invalid);
                }
                let columns = [];
                columns.push(x);
                columns.push(actual);
                columns.push(otherRotation);
                columns.push(valid);
                columns.push(invalid);

                updatedGraph.data.type = "bar";
                updatedGraph.data.columns = columns;
                this.setState({graph: updatedGraph});
            }

            if (data[0].experienceID !== undefined) {
                //andra nivån (experience)
                for (let i = 0; i < data.length; i++) {
                    if (data[i].experienceDetailedName === "") {
                        x.push(" ");
                    } else {
                        x.push(data[i].experienceDetailedName);
                    }
                    actual.push(data[i].actual);
                    const akp = data[i].otherRotation;
                    valid.push(data[i].valid - akp);
                    otherRotation.push(akp);
                    invalid.push(data[i].invalid);
                }
                let columns = [];
                columns.push(x);
                columns.push(actual);
                columns.push(otherRotation);
                columns.push(valid);
                columns.push(invalid);

                updatedGraph.data.type = "bar";
                updatedGraph.data.columns = columns;
                this.setState({graph: updatedGraph});
            }

            if (data[0].reason !== undefined) {
                //sista nivån, (dagnivå)
                let columns = [];
                let reasonList = [];
                for (let i = 0; i < data.length; i++) {
                    if (!reasonList.includes(data[i].reasonTranslated)) {
                        reasonList.push(data[i].reasonTranslated);
                    }
                }

                for (let j = 0; j < reasonList.length; j++) {
                    let array = [];
                    array.push(reasonList[j]);
                    x.push(reasonList[j]);
                    columns.push(array);
                }
                columns.push(x);
                //god have mercy on your soul (gör nåt åt det här)
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < columns.length; j++) {
                        if (columns[j][0] === data[i].reasonTranslated) {
                            if (columns[j].length === 1) {
                                columns[j].push(1);
                            } else {
                                columns[j][1] += 1;
                            }
                        }
                    }
                }

                updatedGraph.data.columns = columns;
                updatedGraph.data.type = "pie";
                //?
                // let label = (value) =>  d3.format('$')(value);
                //let foo = label();
                //updatedGraph["pie"] = {label: {foo}};
                //console.log(updatedGraph);
                this.setState({graph: updatedGraph});
            }

        } else {
            const actionName = this.props.actionName;
            const version = this.props.actionVersion;
            fetchStatistics(this, actionName, version);
        }
    }

    localUpdateGraph(idString) {
        let wantedData = this.searchForData(idString, this.state.dataExp.categories);
        this.updateGraphWithData(wantedData);
    };

    localUpdateGraphParent(idString) {
        let wantedData = this.searchForParent(idString, this.state.dataExp.categories);
        this.updateGraphWithData(wantedData);
    }

    searchForParent(inputName, state) {
        let searchedValue = "";

        state.forEach(categories => {
            if (categories.name === inputName) {
                //this.setState({graph: this.state.originalGraph});
            }
        });

        if (searchedValue === "") {
            state.forEach(categories => {
                categories.experienceSummaries.forEach(expSum => {
                    if (expSum.name === inputName) {
                        searchedValue = categories.experienceSummaries;
                    }
                })
            });
        }

        if (searchedValue === "") {
            state.forEach(categories => {
                categories.experienceSummaries.forEach(expSum => {
                    expSum.experiences.forEach(exp => {
                        if (exp.experienceID === inputName) {
                            searchedValue = expSum.experiences;
                        }
                    })
                })
            });
        }
        return searchedValue;
    }

    searchForData(inputName, state) {
        //detta görs stegvist, om alla undernivår skulle heta samma sak skulle det vara lättare att köra igenom alla rekursivt
        let searchedValue = "";
        //stäng ögonen och ignorera denna lösning på söksystem
        //kanske gör nåt som söker via namnet x.y.z ?
        state.forEach(categories => {
            if (categories.name === inputName) {
                searchedValue = categories.experienceSummaries;
            }
        });

        if (searchedValue === "") {
            state.forEach(categories => {
                categories.experienceSummaries.forEach(expSum => {
                    if (expSum.name === inputName) {
                        searchedValue = expSum.experiences;
                    }
                })
            });
        }

        if (searchedValue === "") {
            state.forEach(categories => {
                categories.experienceSummaries.forEach(expSum => {
                    expSum.experiences.forEach(exp => {
                        if (exp.experienceID === inputName) {
                            searchedValue = exp.experienceDays;
                        }
                    })
                })
            });
        }
        return searchedValue;
    }

    flipActive(e) {
        if (e.target.classList.contains("active-exp-plus")) {
            e.target.classList.remove("active-exp-plus");
            this.setState({expandAbsence: "+"});
        } else {
            e.target.classList.add("active-exp-plus");
            this.setState({expandAbsence: "-"});
        }
    }
}

export default ExperienceDetailsPage;