import {User} from "../model/User";
import {NavBar} from "../model/NavBar";
import {Organisation} from "../model/Organisation";
import {Message, Result} from "../../infra/BackendContext";
import TranslationService from "../../infra/TranslationService";
import {SelectOrganisationResponse} from "./SelectOrganisationResponse";
import {hasRight} from "../../utils/HasRight";

const url: string = '/api/v1/select/organisation';

export function selectOrganisation(organisationId: string,
                                   user: User,
                                   updateNavBar: (navBar: NavBar) => void,
                                   setCurrentOrganisation: (organisation: Organisation | undefined) => void,
                                   post: (url: string, payload: any, message?: Message) => Promise<Result>,
                                   fetchingOrganisation: (fetchingNavBar: boolean) => void
): void {
    const selectedOrganisations: Organisation[] = getSelectedOrganisations(organisationId, user);

    if (selectedOrganisations.length > 0) {
        fetchingOrganisation(true);

        const organisation: Organisation = selectedOrganisations[0];

        const payload: { organisationId: string } = {organisationId: organisationId};
        const failureMessage: string = TranslationService.translation('Failed to select where you work today. Reload LogEze and do a new attempt. If the problem persists, contact us at support@kogeze.com');
        const res: Promise<Result> = post(url, JSON.stringify(payload), {
            failure: failureMessage
        });

        res.then((result: Result) => {
            if (result.success) {
                const data = result.data;
                if (data !== undefined) {
                    const selectOrganisationResponse: SelectOrganisationResponse = data as SelectOrganisationResponse;
                    updateNavBar(selectOrganisationResponse.navBar);
                    user.rights = selectOrganisationResponse.rights;
                    setCurrentOrganisation(organisation);
                    window.location.hash = '#/';
                }
            }
            fetchingOrganisation(false);
        });
    }
}

export function selectDefaultOrganisation(currentOrganisation: Organisation | undefined,
                                          user: User,
                                          updateNavBar: (navBar: NavBar) => void,
                                          setCurrentOrganisation: (organisation: Organisation | undefined) => void,
                                          post: (url: string, payload: any, message?: Message) => Promise<Result>,
                                          fetchingOrganisation: (fetchingNavBar: boolean) => void
): void {
    fetchingOrganisation(true);

    let payload: {} = {};
    if (currentOrganisation !== undefined) {
        payload = {
            organisationId: currentOrganisation.organisationId
        }
    }
    const failureMessage: string = TranslationService.translation('Failed to select where you work today. Reload LogEze and do a new attempt. If the problem persists, contact us at support@kogeze.com');
    const res: Promise<Result> = post(url, JSON.stringify(payload), {
        failure: failureMessage
    });

    res.then((result: Result) => {
        if (result.success) {
            const data = result.data;
            if (data !== undefined) {
                const selectOrganisationResponse: SelectOrganisationResponse = data as SelectOrganisationResponse;
                updateNavBar(selectOrganisationResponse.navBar);
                user.rights = selectOrganisationResponse.rights;
                setCurrentOrganisation(currentOrganisation);
            }
        }
        fetchingOrganisation(false);
    });
}

function getSelectedOrganisations(organisationId: string, user: User): Organisation[] {
    const organisations: Organisation[] = user.organisations;
    const flattenedOrganisations: Organisation[] = [];

    for (let index: number = 0; index < organisations.length; index++) {
        const organisation: Organisation = organisations[index];
        const someOrganisations = flattenOrganisations(organisation);
        flattenedOrganisations.push(...someOrganisations);
    }

    return flattenedOrganisations.filter((value: Organisation) => organisationId === value.organisationId);
}

function flattenOrganisations(src: Organisation): Organisation[] {
    if (src.children.length === 0) {
        return [src];
    } else {
        const collector: Organisation[] = [];
        collector.push(src);
        for (let index: number = 0; index < src.children.length; index++) {
            let child: Organisation = src.children[index];

            collector.push(...flattenOrganisations(child));
        }

        return collector;
    }
}

export function shouldSeeOrganisationSelector(user: User): boolean {
    const hasOrgSelectorRight = hasRight(user, "organisation-selector", "interface access");
    const organisations = user.organisations;

    return hasOrgSelectorRight && organisations.length > 0 &&
        (organisations.length > 1 || organisations[0].organisationDescription?.includes("Placeholder for no organisation")
            || organisations[0].children?.length > 0);
}